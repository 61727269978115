// auth section
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESEND_OTP = "RESEND_OTP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CONTACT_MESSAGE = "CONTACT_MESSAGE";
export const GET_STARTED_VIDEOS = "GET_STARTED_VIDEOS";
export const GET_FAQ = "GET_FAQ";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const ADD_CARD_DETAIL = "ADD_CARD_DETAIL";
export const BUY_SUBSCRIPTION = "BUY_SUBSCRIPTION";
export const UPDATE_SUBSCRIBE = "UPDATE_SUBSCRIBE";
export const GET_ACADEMY_STUDENT = "GET_ACADEMY_STUDENT";
export const DELETE_ACADEMY_STUDENT = "DELETE_ACADEMY_STUDENT";
export const CHECK_SUBSCRIPTION_BY_EMAIL = "CHECK_SUBSCRIPTION_BY_EMAIL";
export const VERIFY_SUBSCRIPTION_OTP = "VERIFY_SUBSCRIPTION_OTP";
export const ADD_CARD_TRIAL = "ADD_CARD_TRIAL";
export const ADD_ACADEMY_ENQUIRY = "ADD_ACADEMY_ENQUIRY";
export const CREATE_FEEDBACK_TICKET = "CREATE_FEEDBACK_TICKET";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

// profile section
export const PROFILE_DATA = "PROFILE_DATA";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ADD_USER_DETAIL = "ADD_USER_DETAIL";

// Blog section
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const BLOG_COMMENTS = "BLOG_COMMENTS";
export const SHOW_BLOG_COMMENTS = "SHOW_BLOG_COMMENTS";
export const DEL_BLOG_COMMENTS = "DEL_BLOG_COMMENTS";

// community section
export const GET_ALL_COMMUNITY = "GET_ALL_COMMUNITY";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const POST_COMMENTS = "POST_COMMENTS";
export const DEL_COMMENTS = "DEL_COMMENTS";
export const ADD_POST = "ADD_POST";
export const DEL_POST = "DEL_POST";
export const UPDATE_POST = "UPDATE_POST";
export const LIKE_POST = "LIKE_POST";

// Program section
export const GET_PROGRAM_DETAIL = "GET_PROGRAM_DETAIL";
export const GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS";
export const GET_SINGLE_PROGRAMS = "GET_SINGLE_PROGRAMS";
export const GET_PARTICULAR_CYCLE_PROGRAMS = "GET_PARTICULAR_CYCLE_PROGRAMS";
export const GET_ALL_WORKOUTS = "GET_ALL_WORKOUTS";
export const GET_MY_PROGRAMS = "GET_MY_PROGRAMS";
export const FOLLOW_PROGRAMS = "FOLLOW_PROGRAMS";
export const GET_STATS_DATA = "GET_STATS_DATA";
export const MARK_WORKOUT = "MARK_WORKOUT";
export const UN_MARK_WORKOUT = "UN_MARK_WORKOUT";
export const MARK_SESSION_COMPLETE = "MARK_SESSION_COMPLETE";
export const GET_BADGES_DATA = "GET_BADGES_DATA";
export const GET_PROGRESS_DATA = "GET_PROGRESS_DATA";

// Product section
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const PRODUCTS_DETAILS = "PRODUCTS_DETAILS";
export const PRODUCT_CATEGORY = "PRODUCT_CATEGORY";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const REVIEWS_DATA = "REVIEWS_DATA";
export const ADD_CART = "ADD_CART";
export const GET_CART_DATA = "GET_CART_DATA";
export const DELETE_CART = "DELETE_CART";
export const UPDATE_CART = "UPDATE_CART";
export const ALL_ADDRESS = "ALL_ADDRESS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const ORDER_PLACE = "ORDER_PLACE";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const GET_ORDERS_DETAILS = "GET_ORDERS_DETAILS";
export const REORDER_PRODUCT = "REORDER_PRODUCT";
export const ADD_CART_BEFORE = "ADD_CART_BEFORE";
export const GET_CART_BEFORE = "GET_CART_BEFORE";
export const GET_SELL_PROGRAMS = "GET_SELL_PROGRAMS";

// Journal section
export const GET_ALL_CLASS = "GET_ALL_CLASS";
export const GET_JOURNAL_DOTS = "GET_JOURNAL_DOTS";
export const ADD_BJJ_CLASS = "ADD_BJJ_CLASS";
export const ADD_CUSTOM_CLASS = "ADD_CUSTOM_CLASS";
export const DELETE_BJJ_CLASS = "DELETE_BJJ_CLASS";
export const DELETE_CUSTOM_CLASS = "DELETE_CUSTOM_CLASS";
export const GET_JOURNAL_LIST = "GET_JOURNAL_LIST";
export const ADD_JOURNAL_LIST = "ADD_JOURNAL_LIST";
export const DELETE_JOURNAL_LIST = "DELETE_JOURNAL_LIST";
export const PREVIOUS_JOURNAL_LIST = "PREVIOUS_JOURNAL_LIST";


// Store Common Data
export const STORE_DATA = "STORE_DATA";
export const PODCAST_DATA = "PODCAST_DATA";