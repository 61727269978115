import { GET_ALL_PROGRAMS, GET_SINGLE_PROGRAMS, GET_PARTICULAR_CYCLE_PROGRAMS, GET_ALL_WORKOUTS, GET_MY_PROGRAMS, FOLLOW_PROGRAMS, GET_STATS_DATA, GET_BADGES_DATA, MARK_WORKOUT, UN_MARK_WORKOUT, MARK_SESSION_COMPLETE, GET_PROGRESS_DATA, GET_PROGRAM_DETAIL, GET_SELL_PROGRAMS } from "../actions/types";

const initialState = {
   program_data: null,
   program_detail_data: null,
   single_program: null,
   particular_cycle_data: null,
   get_all_workouts: null,
   get_my_program: null,
   follow_program: null,
   get_stats_data: null,
   get_badges_data: null,
   mark_workout: null,
   un_mark_workout: null,
   mark_session_complete: null,
   get_progress_data: null,
   get_sell_programs: null,
}

export default function (state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case GET_ALL_PROGRAMS:
         return {
            ...state,
            program_data: payload
         }
      case GET_PROGRAM_DETAIL:
         return {
            ...state,
            program_detail_data: payload
         }

      case GET_SINGLE_PROGRAMS:
         return {
            ...state,
            single_program: payload
         }

      case GET_PARTICULAR_CYCLE_PROGRAMS:
         return {
            ...state,
            particular_cycle_data: payload
         }

      case GET_ALL_WORKOUTS:
         return {
            ...state,
            get_all_workouts: payload
         }

      case GET_MY_PROGRAMS:
         return {
            ...state,
            get_my_program: payload
         }

      case FOLLOW_PROGRAMS:
         return {
            ...state,
            follow_program: payload
         }

      case GET_STATS_DATA:
         return {
            ...state,
            get_stats_data: payload
         }

      case MARK_WORKOUT:
         return {
            ...state,
            mark_workout: payload
         }

      case UN_MARK_WORKOUT:
         return {
            ...state,
            un_mark_workout: payload
         }

      case MARK_SESSION_COMPLETE:
         return {
            ...state,
            mark_session_complete: payload
         }

      case GET_BADGES_DATA:
         return {
            ...state,
            get_badges_data: payload
         }

      case GET_PROGRESS_DATA:
         return {
            ...state,
            get_progress_data: payload
         }

      case GET_SELL_PROGRAMS:
         return {
            ...state,
            get_sell_programs: payload
         }

      default:
         return state;
   }
}